
import Slider from 'react-slick';
import { stopClick } from '../components/Stop';
import '../css/slider.css';
import { Link } from 'react-router-dom';
import ClientSlider from '../components/ClientSlider';
import React from 'react'
import ProjectSlider from '../components/ProjectSlider';
import { useState } from 'react';







const Home = () => {
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        interestedIn: '',
        message: '',
      };
      const [formData, setFormData] = useState(initialFormData);
      const [successMessage, setSuccessMessage] = useState('');
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };

    //   const services = [{
    //     name: 'App Development'
    //   },
    //   {
    //     name: 'Website Development'
    //   },
    //   {
    //     name: 'Mobile Development'
    //   }];
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        fetch('https://aashvienterprises.in/admin/enquiry.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            console.log('Success:', data);
            setSuccessMessage('Thank You for contacting us');
            setFormData(initialFormData);
            // Clear the success message after 4 seconds
        setTimeout(() => {
            setSuccessMessage('');
          }, 4000); // Reset form fields to initial values
          })
          .catch((error) => {
            console.error('Error:', error.message);
          });
      };


    /*------slider js -----------*/
    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return <button className={className} onClick={onClick}><i className="fa-solid fa-caret-left"></i></button>;
    };

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return <button className={className} onClick={onClick}><i className="fa-solid fa-caret-right"></i></button>;
    };

    const settings = {
        slidesToShow: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        autoplay: true,
        dots: false,
        autoplaySpeed: 2000,
        fade: true
    };

    return (
        <>
        
            <section className="hero-slider">
                <Slider {...settings}>
                    <div className="hero-slide slider_1">
                        <div className="slider-overlay slider-1-overlay"></div>
                        
                        <div className="container">
                            <div className="slide-content text-white text-white">
                                <h2 className="h1 mb30 aos-init aos-animate">Create Websites Beyond Your Imagination</h2>
                                <p data-aos="fade-in" data-aos-delay="500" className="aos-init aos-animate">Evolve your web experiences and create stunning, feature-rich websites.</p>
                                <Link className=" ree-btn ree-btn-grdt1 mt40 mw-80 aos-init aos-animate" to="/contact" data-aos="fade-in" data-aos-delay="800">Let's Talk <i className="fas fa-arrow-right fa-btn"></i></Link>



                            </div>
                        </div>
                    </div>
                    <div className="hero-slide slider_2">
                        <div className="slider-overlay slider-2-overlay"></div>
                        <div className="container">
                            <div className="slide-content text-white text-white">
                                <h2 className="h1 mb30 aos-init aos-animate">Create Websites Beyond Your Imagination</h2>
                                <p data-aos="fade-in" data-aos-delay="500" className="aos-init aos-animate">Evolve your web experiences and create stunning, feature-rich websites.</p>
                                <Link className=" ree-btn ree-btn-grdt1 mt40 mw-80 aos-init aos-animate" to="contact" data-aos="fade-in" data-aos-delay="800">Let's Talk <i className="fas fa-arrow-right fa-btn"></i></Link>



                            </div>
                        </div>
                    </div>

                    <div className="hero-slide slider_3">
                        <div className="slider-overlay slider-3-overlay"></div>
                        <div className="container">
                            <div className="slide-content text-white text-white">
                                <h2 className="h1 mb30 aos-init aos-animate">Create Websites Beyond Your Imagination</h2>
                                <p data-aos="fade-in" data-aos-delay="500" className="aos-init aos-animate">Evolve your web experiences and create stunning, feature-rich websites.</p>
                                <Link className=" ree-btn ree-btn-grdt1 mt40 mw-80 aos-init aos-animate" to="/contact" data-aos="fade-in" data-aos-delay="800">Let's Talk <i className="fas fa-arrow-right fa-btn"></i></Link>



                            </div>
                        </div>
                    </div>

                    <div className="hero-slide slider_4">
                        <div className="slider-overlay slider-4-overlay"></div>
                        <div className="container">
                            <div className="slide-content text-white text-white">
                                <h2 className="h1 mb30 aos-init aos-animate">Create Websites Beyond Your Imagination</h2>
                                <p data-aos="fade-in" data-aos-delay="500" className="aos-init aos-animate">Evolve your web experiences and create stunning, feature-rich websites.</p>
                                <Link className=" ree-btn ree-btn-grdt1 mt40 mw-80 aos-init aos-animate" to="/contact" data-aos="fade-in" data-aos-delay="800">Let's Talk <i className="fas fa-arrow-right fa-btn"></i></Link>



                            </div>
                        </div>
                    </div>


                </Slider>

            </section>
            <section className="home-about sec-pad r-bg-a">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-content-home m-mb60 pera-block">
                                <span className="sub-heading mb15">Aashvi Enterprises</span>
                                <h2> Web Development & Digital Marketing Company</h2>
                                <p>Aashvi Enterprises is a Marketing and Advertising Agency
                                    catering services all over the states in India. Aashvi Enterprises
                                    is into Services like Bulk SMS, WhatsApp Marketing,
                                    Digital Marketing Voice Calls and many more. Aashvi enterprises
                                    is presently serving its services throughout India. Wit
                                    our values attached to our core, we firmly believe in
                                    providing our customers with high quality work.

                                </p>

                                <div className="btn-sets">
                                    <Link to="about.php" className="ree-btn  ree-btn-grdt2 mr20">About Us <i
                                        className="fas fa-arrow-right fa-btn"></i></Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="company-stats">
                                <div className="row">
                                    <div className="col-lg-6 col-6 m-pr7">
                                        <div className="stats-box stat-bx-1" data-aos="fade-up" data-aos-delay="200">
                                            <span className="sub-heading mb20">Projects</span>
                                            <h3>200<span>+</span></h3>
                                            <p className="mt20">
                                            </p>
                                        </div>
                                        <div className="stats-box stat-bx-2 mt20" data-aos="fade-up" data-aos-delay="600">
                                            <span className="sub-heading mb20">Team</span>
                                            <h3>20<span>+</span></h3>
                                            <p className="mt20"></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6 m-pl7">
                                        <div className="stats-box stat-bx-3 mt100" data-aos="fade-up" data-aos-delay="400">
                                            <span className="sub-heading mb20">Clients</span>
                                            <h3>150<span>+</span></h3>
                                            <p className="mt20"></p>
                                        </div>
                                        <div className="stats-box stat-bx-4 mt20" data-aos="fade-up" data-aos-delay="800">
                                            <span className="sub-heading mb20">Years</span>
                                            <h3>2<span>+</span></h3>
                                            <p className="mt20">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="r-bg-i sec-pad">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="sec-heading text-center pera-block">
                                <h2> We Offers</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row mt30">
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                            <div className="ree-card r-bg-c mt60">
                                <div className="ree-card-img shadows"><img src="images/icons/service1.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">SEO Optimization</Link></h3>
                                    <p>Search Engine Optimization services can be a tremendous help to business owners.</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="service-detai.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="300">
                            <div className="ree-card r-bg-c  mt60">
                                <div className="ree-card-img"><img src="images/icons/service2.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">Digital Marketing</Link></h3>
                                    <p>Digital Marketing moves at an overwhelming rate. We capture the emotion</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="services.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="500">
                            <div className="ree-card r-bg-c  mt60">
                                <div className="ree-card-img"><img src="images/icons/service3.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">Web Design</Link></h3>
                                    <p>Web design include different skills and disciplines in the production and maintenance of websites.</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="services.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="700">
                            <div className="ree-card r-bg-c  mt60">
                                <div className="ree-card-img"><img src="images/icons/service4.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">Web Development</Link></h3>
                                    <p>Website development is a broad term for developing web sites. It refers to the tasks associated</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="services.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="900">
                            <div className="ree-card r-bg-c  mt60">
                                <div className="ree-card-img"><img src="images/icons/service5.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">App Development</Link></h3>
                                    <p>We offers advanced services in the field of mobile application development across various</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="services.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="1100">
                            <div className="ree-card r-bg-c  mt60">
                                <div className="ree-card-img"><img src="images/icons/service6.png" alt="services" /> </div>
                                <div className="ree-card-content mt40">
                                    <h3 className="mb15"><Link to="services.php">Social Marketing</Link></h3>
                                    <p>SMO service is the best way which can spread a news like a FIRE. Promoting a product or service</p>
                                </div>
                                <div className="ree-card-content-link">
                                    <Link to="services.php" className="ree-card-link mt40">Read More <i
                                        className="fas fa-arrow-right fa-btn"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cta-block-wide mt100">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-10 vcenter">
                                <div className="cta-heading-wide-bt">

                                    <Link to="get-quote.php" className="ree-btn  ree-btn-grdt1 mw-80">Hire Now <i
                                        className="fas fa-arrow-right fa-btn"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <ProjectSlider />


            <section className="r-bg-x zup sec-pad">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="sec-heading text-center pera-block">
                                <h2> Technology We Use</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10 mt60">
                            <div className="tab-17 tabs-layout">
                                <ul className="nav nav-tabs justify-content-center" id="myTab3" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="tab1a" data-toggle="tab" href="#tab1" role="tab"
                                            aria-controls="tab1" aria-selected="true">Mobile</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="tab2b" data-toggle="tab" href="#tab2" role="tab"
                                            aria-controls="tab2" aria-selected="false">Front End</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="tab3c" data-toggle="tab" href="#tab3" role="tab"
                                            aria-controls="tab3" aria-selected="false">Database </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="tab4c" data-toggle="tab" href="#tab4" role="tab"
                                            aria-controls="tab4" aria-selected="false">CMS </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="tab5c" data-toggle="tab" href="#tab5" role="tab"
                                            aria-controls="tab5" aria-selected="false">Back End </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent3">
                                    <div className="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1a">
                                        <div className="tab-data-cont">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/apple.svg"
                                                                alt="Apple" /> </div>
                                                            <div className="iwt-content">
                                                                <p>iOS</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/android.svg"
                                                                alt="Android" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Android</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/flutterio-icon.svg"
                                                                alt="Flutter" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Flutter</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/java.svg" alt="Java" />
                                                            </div>
                                                            <div className="iwt-content">
                                                                <p>Java</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/physics.svg"
                                                                alt="React Native" /> </div>
                                                            <div className="iwt-content">
                                                                <p>React Native</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>


                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/swift.svg"
                                                                alt="Apswiftple" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Swift</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2b">
                                        <div className="tab-data-cont">
                                            <div className="row justify-content-center">

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/physics.svg"
                                                                alt="React JS" /> </div>
                                                            <div className="iwt-content">
                                                                <p>React JS</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>


                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/html-5.svg"
                                                                alt="html-5" /> </div>
                                                            <div className="iwt-content">
                                                                <p>HTML5</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/bootstrap.svg"
                                                                alt="bootstrap" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Bootstrap</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/css-3.png"
                                                                alt="Node.js_logo" /> </div>
                                                            <div className="iwt-content">
                                                                <p>css</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/js.png"
                                                                alt="Node.js_logo" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Javascript</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/jquery.png"
                                                                alt="Node.js_logo" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Jquery</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5c">
                                        <div className="tab-data-cont">
                                            <div className="row justify-content-center">

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/php.png"
                                                                alt="React JS" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Php</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>


                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/laravel.png"
                                                                alt="html-5" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Laravel</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/codeigniter.png"
                                                                alt="bootstrap" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Codeigniter</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3c">
                                        <div className="tab-data-cont">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/MariaDB.svg"
                                                                alt="MariaDB" /> </div>
                                                            <div className="iwt-content">
                                                                <p>MariaDB</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/mongodb-icon.svg"
                                                                alt="mongodb-icon" /> </div>
                                                            <div className="iwt-content">
                                                                <p>MongoDB</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/mysql.svg"
                                                                alt="mysql-official" /> </div>
                                                            <div className="iwt-content">
                                                                <p>MySQL</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/postgresql-icon.svg"
                                                                alt="postgresql-icon" /> </div>
                                                            <div className="iwt-content">
                                                                <p>PostgreSQL</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/oracle.svg"
                                                                alt="oracle-logo" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Oracle</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4c">
                                        <div className="tab-data-cont">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/shopify.svg"
                                                                alt="shopify" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Shopify</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                                    <div className="icon-with-title">
                                                        <Link to="#" onClick={stopClick}>
                                                            <div className="iwt-icon"> <img src="images/icons/wordpress.svg"
                                                                alt="wordpress" /> </div>
                                                            <div className="iwt-content">
                                                                <p>Wordpress</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>








            <section className="home-contact pb120" data-background="images/banner/1.png">
                <div className="container">
                    <div className="row zup flx-end">
                        <div className="col-right-a">


                        </div>
                        <div className="col-right-b">
                            <div className="form-contact-hom fourc-up-b">
                                <div className="form-block">
                                    <div className="form-head">
                                        <h3>Write To Us</h3>
                                        {successMessage && <p className="success-message text-success mt-2">{successMessage}</p>}
                                    </div>
                                   
                                    <div className="form-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="fieldsets row">
                                                <div className="col-md-6"><input type="text" placeholder="Full Name" name="name" value={formData.name} onChange={handleInputChange} />
                                                </div>
                                                <div className="col-md-6"><input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} /></div>
                                            </div>
                                            <div className="fieldsets row">
                                                <div className="col-md-6"><input type="number" name="phone" placeholder="Contact Number" value={formData.phone} onChange={handleInputChange}
                                                /></div>


                                                <div className="col-md-6">
                                                    <select name="interestedIn" value={formData.interestedIn} onChange={handleInputChange}>
                                                        <option value="">interested in</option>
                                                        <option value="Web Design & Development">Web Design & Development</option>
                                                        <option value="Graphic Design">Graphic Design</option>
                                                        <option value="App Development">App Development</option>
                                                        <option value="Digital Marketing">Digital Marketing</option>

                                                        <option value="Other...">Other...</option>
                                                    </select >
                                                </div>
                                            </div>
                                            <div className="fieldsets"><textarea placeholder="Message" name="message" value={formData.message} onChange={handleInputChange}></textarea>
                                            </div>

                                            <div className="fieldsets mt20">

                                                <button type="submit" id="butsave" name="submit"
                                                    className="ree-btn  ree-btn-grdt1 w-100">Send your inquiry <i
                                                        className="fas fa-arrow-right fa-btn"></i></button> </div>
                                            <p className="trm"><i className="fas fa-lock"></i>We hate spam, and we respect your privacy.
                                            </p>
                                        </form>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ClientSlider />

        </>
    )
}

export default Home
