import React from 'react'

const Carrer = () => {
    return (
        <>

            <section className="home-partners-block r-bg-a sec-pad" id="job">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="sec-heading text-center">
                                <h2 className="mb15">We're <span className="ree-text rt40">Hiring!</span></h2>
                                <p>Lets Join Us</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt30">
                        <div className="col-lg-4 mt30">
                            <div className="job-detail ree-card">
                                <h4>Business Development Executive</h4>
                                <div className="job-info w-100 mt30">
                                    <div className="career-detail">
                                        <p>Required Experience</p>
                                        <p>1 to 5 years</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Number of Position</p>
                                        <p>1</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Job Location</p>
                                        <p>Noida, India</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Job Type</p>
                                        <p>Full Time</p>
                                    </div>
                                </div>
                                <div className="job-titl mt40">
                                    <p>10 days Ago</p>
                                    <a href="get-quote.php" className="ree-btn  ree-btn-grdt2">Apply Now <i className="fas fa-arrow-right fa-btn"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt30">
                            <div className="job-detail ree-card">
                                <h4>Digital Marketing Executive</h4>
                                <div className="job-info w-100 mt30">
                                    <div className="career-detail">
                                        <p>Required Experience</p>
                                        <p>1 to 5 years</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Number of Position</p>
                                        <p>1</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Job Location</p>
                                        <p>Noida, India</p>
                                    </div>
                                    <div className="career-detail">
                                        <p>Job Type</p>
                                        <p>Full Time</p>
                                    </div>
                                </div>
                                <div className="job-titl mt40">
                                    <p>1 days Ago</p>
                                    <a href="get-quote.php" className="ree-btn  ree-btn-grdt2">Apply Now <i className="fas fa-arrow-right fa-btn"></i></a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Carrer
