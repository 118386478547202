import React, { useEffect, useState } from 'react'
import ClientSlider from '../components/ClientSlider'
import { Link } from 'react-router-dom';
import axios from 'axios';
const Portfolio = () => {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const isValidUrl = (url) => {
    // Regular expression to check if the string is a valid URL
    const pattern = new RegExp('^https?://.+');
    return pattern.test(url);
  };

  useEffect(() => {
    axios.get('https://aashvienterprises.in/admin/api.php?action=get_json')
      .then(response => {
        // console.log('API Response:', response.data);
       
          // Sort the data based on the 'id' property in descending order
      const sortedData = response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
        setUsers(sortedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setUsers([]); // Set users to an empty array to handle the error response
        setLoading(false);
      });
  }, []);

  if (loading) {
    // Show a loading message or spinner while waiting for the API response
    return <p>Loading...</p>;
  }

  if (!Array.isArray(users) || users.length === 0) {
    return (
      <div className="col-lg-12 text-center mt-5 pt-5">
        <p>No products to show.</p>
        =
      </div>
    );
  }

  return (
    <>
      <section className="header-title head-opacity" data-background="images/banner/3.png" style={{ backgroundImage: `url(/images/banner/3.png)` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center ovrly">
              <h1 className="mb15">Our Projects</h1>
            </div>
          </div>
        </div>
      </section>


      <div className="sec-pad r-bg-a">
        <div className="container">

          <div className="row justify-content-center mt70">

       
          {users.map(user => (
            <div className="col-lg-3 col-md-6  col-sm-6 mt30" key={user.id} >
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                <img src={user.image} alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">{user.username}</p>
                  {isValidUrl(user.email) ? (
                      <h4>
                        <Link to={user.email} target="_blank" rel="noreferrer">
                          {user.education}
                        </Link>
                      </h4>
                    ) : (
                      <h4>{user.education}</h4>
                    )}
                </div>
              </div>
            </div>
 ))
                    }

{/* 
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/genx.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Web Design & Development</p>
                  <h4>Website</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/iron.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Web Design & Development</p>
                  <h4>Website</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/libas.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Web Design & Development</p>
                  <h4>Website</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/suruppam.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Web Design & Development</p>
                  <h4>Website</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/tip-top.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Web Design & Development</p>
                  <h4>Website</h4>
                </div>
              </div>
            </div>
            <br />
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/glam.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4>Social Media Post</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/himani.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4>Logo</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/hi-tech.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4>Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/mu.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/mu2.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4>Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/mu3.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Social Media Post</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/mu4.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4>Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/naxa.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Logo </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/snow.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/snow2.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Social Media Post </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/snow-logo.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Logo </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  col-sm-6 mt30">
              <div className="fwb-main-x fwb-a">
                <div className="work-thumbnails">
                  <img src="images/portfolio/tip.png" alt="" className="img-fluid" />
                </div>
                <div className="work-details">
                  <p className="mb10">Graphic Design</p>
                  <h4> Social Media Post </h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ClientSlider />
    </>
  )
}

export default Portfolio
