import React from 'react'

const Service = () => {
  return (
    <>
      


<section className="page-heading-sec serv-page-head r-bg-a pt85 pb120">
<div className="container">
<div className="row pt80 justify-content-center">
<div className="col-lg-9">
<div className="page-headings service-page">

<h1 data-aos="fade-in" data-aos-delay="400">Our Services</h1>

</div>
</div>
</div>

</div>
</section>


<section className="r-bg-x  sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-4">
<div className="page-headings m-center">

<a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mt40">Need a Designer <i className="fas fa-arrow-right fa-btn"></i></a>
</div>
</div>
<div className="col-lg-8">
<div className="ml25 m-ml0 m-mt60">
<h2 className="h1a m-center">Design Service</h2>
<div className="services-blocks-set mt30">
<div className="servic-block-a">
<img src="images/icons/web-design.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15"> Websites Design </h4>
<p>We create comprehensive and sophisticated yet simple to use interfaces for your web app.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/logo-design.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Logo Design</h4>
<p>A recognizable graphic design element, often including a name, symbol or trademark, representing an organization or product.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/printing.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Brochure Design</h4>
<p>Aashvi helps you to take care that your brochure should stand out from others and our team specializes in designing a brochure that appeals to the customers.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/illustration.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Graphic Design</h4>
<p>A good visual of the content has a long-lasting impact on the brain. If you’re looking for an Amazing Graphic Designing service then you came to the right place.</p>
</div>
</div>
</div>
</div>
</div>
</div>
</section>


<section className="r-bg-a  sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-4">
<div className="page-headings m-center">

<a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mt40">Need a Developer <i className="fas fa-arrow-right fa-btn"></i></a>
</div>
</div>
<div className="col-lg-8">
<div className="ml25 m-ml0 m-mt60">
<h2 className="h1a m-center">Development Service</h2>
<div className="services-blocks-set mt30">
<div className="servic-block-a">
<img src="images/icons/appcode.svg" alt="service" className="icon70 mb20"/>

<h4 className="mb15">App Development</h4>
<p>At Aashvi, we develop applications that are built with a powerful set of features that are tailor-made to fit your brand.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/coding.svg" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Web Development</h4>
<p>With multiple years of industry experience in the industry, our company helps many businesses, build cutting-edge B2B & B2Cwebsites</p>
</div>
<div className="servic-block-a">
<img src="images/icons/buy-online.svg" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Ecommerce Development</h4>
<p>Ecommerce- Our eCommerce web developers run through your needs carefully, identify the issues, and define the scopes to form the eCommerce website development plan.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/api.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">API Development</h4>
<p>We are highly professional in creating new APIs and integrating your application with an interface of an existing API.</p>
</div>
</div>
</div>
</div>
</div>
</div>
</section>


<section className="r-bg-x  sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-4">
<div className="page-headings m-center">

<a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mt40">Need a Markegist <i className="fas fa-arrow-right fa-btn"></i></a>
</div>
</div>
<div className="col-lg-8">
<div className="ml25 m-ml0 m-mt60">
<h2 className="h1a m-center">Marketing Service</h2>
<div className="services-blocks-set mt30">
<div className="servic-block-a">
<img src="images/icons/search-engine-optimization.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Search Engine Optimization</h4>
<p>Digital Marketing is all about presence. You need to be in front of the eyes of potential customers always to create a lasting impact on their minds.</p>
</div>
<div className="servic-block-a">
<img src="images/icons/optimization.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Social Media Optimization</h4>
<p>Another important digital marketing strategy, social media optimization is a unique way to grow and manage your business presence on different social media platforms. </p>
</div>
<div className="servic-block-a">
<img src="images/icons/ppc.svg" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Google Ads</h4>
<p>Google ads are a way to advertise your products/services on Google. This will help you to give the right message to your audience. </p>
</div>
<div className="servic-block-a">
<img src="images/icons/brand.png" alt="service" className="icon70 mb20"/>

<h4 className="mb15">Branding</h4>
<p>Our branding services are solely designed to improve your image in front of your customers</p>
</div>
</div>
</div>
</div>
</div>
</div>
</section>


<section className="r-bg-d  sec-pad">
<div className="container">
<div className="row justify-content-center">
<div className="col-lg-8">
<div className="page-headings text-center">
<h2 className="mb15">Company Values</h2>
</div>
</div>
</div>
<div className="row justify-content-center mt30">
<div className="col-lg-3 col-md-4 col-sm-6 mt30">
<div className="process-content ree-card">
<span className="setps hst-1">01</span>
<div className="process-block">
<div className="process-icon">
<img src="images/icons/rating.png" alt="service" className="icon70 mb20"/>
</div>
<h4>CLIENT SATISFACTION
</h4>

</div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 mt30">
<div className="process-content ree-card">
<span className="setps hst-2">02</span>
<div className="process-block ">
<div className="process-icon">
 <img src="images/icons/quality.png" alt="service" className="icon70 mb20"/>
</div>
<h4>QUALITY
</h4>

</div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 mt30 ">
<div className="process-content ree-card">
<span className="setps hst-3">03</span>
<div className="process-block">
<div className="process-icon">
<img src="images/icons/satisfaction.png" alt="service" className="icon70 mb20"/>
</div>
<h4>PASSION
</h4>

</div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 mt30">
<div className="process-content ree-card">
<span className="setps hst-4">04</span>
<div className="process-block">
<div className="process-icon">
<img src="images/icons/diversity.png" alt="service" className="icon70 mb20"/>
</div>
<h4>DIVERSITY</h4>

</div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 mt30">
<div className="process-content ree-card">
<span className="setps hst-5">05</span>
<div className="process-block">
<div className="process-icon">
<img src="images/icons/clipboard.png" alt="service" className="icon70 mb20"/>
</div>
<h4>ACCOUNTABILITY</h4>

</div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 mt30">
<div className="process-content ree-card">
<span className="setps hst-6">06</span>
<div className="process-block">
<div className="process-icon">
<img src="images/icons/collaboration.png" alt="service" className="icon70 mb20"/>
</div>
<h4>INTEGRITY</h4>

</div>
</div>
</div>



</div>
</div>
</section>




<section className="sec-pad r-bg-a">
<div className="container">
<div className="ree">
<div className="row">
<div className="col-lg-6 vcenter">
<div className="cta-heading">
<span className="sub-heading mb15">Contact Us</span>
<h2>Have a <span className="ree-text rt40">project</span> in mind? Let's get to chat.</h2>
<a href="get-quote.php" className="ree-btn  ree-btn-grdt1  mt40 mr20"><i className="fas fa-phone-alt fb-bigon"></i> Contact </a>
<a href="https://wa.me/919718600049?text=" className="ree-btn  whatsapp-bg mt40" target="_blank"><i className="fab fa-whatsapp fb-bigon"></i> Whatsapp </a>
</div>
</div>
<div className="col-lg-6 vcenter text-center">
<div className="sol-img-png">
<img src="images/banner/chat.png" alt="working with client" className="img-fluid"/>
</div>
</div>
</div>
</div>
</div>
</section>
    </>
  )
}

export default Service
