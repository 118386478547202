import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ProjectSlider = () => {
     

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const isValidUrl = (url) => {
      // Regular expression to check if the string is a valid URL
      const pattern = new RegExp('^https?://.+');
      return pattern.test(url);
    };
  
  
    useEffect(() => {
      axios.get('https://aashvienterprises.in/admin/api.php?action=get_json')
        .then(response => {
          //  console.log('API Response:', response.data);
              // Sort the data based on the 'id' property in descending order
      const sortedData = response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
          setUsers(sortedData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setUsers([]); // Set users to an empty array to handle the error response
          setLoading(false);
        });
    },[]);
  
    if (loading) {
      // Show a loading message or spinner while waiting for the API response
      return <p>Loading...</p>;
    }
  
    if (!Array.isArray(users) || users.length === 0) {
      return (
        <div className="col-lg-12 text-center mt-5 pt-5">
          <p>No products to show.</p>
         
        </div>
      );
    }



    // slider



    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return <button className={className} onClick={onClick}><i className="fas fa-chevron-left"></i></button>;
      };
    
      const NextArrow = (props) => {
        const { className, onClick } = props;
        return <button className={className} onClick={onClick}><i className="fas fa-chevron-right"></i></button>;
      };

    const settings = {
        dots: false,
       
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        margin:20,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <>
       <section className="r-bg-a sec-pad">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-sm-8 vcenter">
                    <div className="heading-hz-btn">
                        <h2>Our <span className="ree-text rt40">Latest</span> Projects</h2>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-4 vcenter">
                    <div className="link-sol-header">
                        <Link to="portfolio.php" className="ree-card-link">View All <i
                                className="fas fa-arrow-right fa-btn"></i></Link>
                    </div>
                </div>
            </div>
            <div className="row mt60">
                <div className="col-lg-12 full-work-block-2">
                <Slider {...settings}>
                {users.map(user => (
                    <div className="fwb-main-x fwb-a mr-5 " key={user.id} >
                            <div className="work-thumbnails" >
                               <img src={user.image} alt=""
                                        className="img-fluid"/> 
                            </div>
                            <div className="work-details">
                  <p className="mb10">{user.username}</p>
                  {isValidUrl(user.email) ? (
                      <h4>
                        <Link to={user.email} target="_blank" rel="noreferrer">
                          {user.education}
                        </Link>
                      </h4>
                    ) : (
                      <h4>{user.education}</h4>
                    )}
                </div>
                        </div>
                  ))
                }
                    
                    </Slider>
                </div>
            </div>
        </div>
    </section> 
    </>
  )
}

export default ProjectSlider
