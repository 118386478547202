import React from 'react'

const Pricing = () => {
    return (
        <>
            <div className="port-head-sec pt85 pb120 r-bg-x">
                <div className="container">
                    <div className="row vcenter pt80">
                        <div className="col-lg-6">
                            <div className="page-headings">
                                <span className="sub-heading mb15" data-aos="fade-up" data-aos-delay="200"><i className="fa-solid fa-indian-rupee-sign mr-1"></i>Our Pricing</span>
                                <h1 className="mb15" data-aos="fade-up" data-aos-delay="400">Business
                                    Idea Proposals</h1>
                                <p className="h-light" data-aos="fade-up" data-aos-delay="600">A new way of delivering a product
                                </p>
                                <p className="h-light" data-aos="fade-up" data-aos-delay="600">Note
                                </p>

                                <ul>
                                    <li>Website changes may vary according to client's need</li><br />
                                    <li>18% GST applicable.
                                    </li><br />
                                    <li>50% payment to be made advance rest 50% at
                                        completion of work.
                                    </li>
                                </ul>
                                {/* <!-- <a href="#" className="port-links mt40" data-aos="fade-up" data-aos-delay="800">Talk to our Experts <i className="fas fa-arrow-right fa-btn"></i></a> --> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sol-img m-mt30" data-aos="fade-in" data-aos-delay="400"><img src="images/banner/price.png" alt="portfolio" className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="r-bg-a sec-pad">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="page-headings">
                                <h2 className="mb15">Pricing</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt60">
                        <div className="col-lg-12">
                            <div className="header--btn  mb30">
                                <h4>Website Development Packages</h4>
                                <a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mr20">Request A Quote <i className="fas fa-arrow-right fa-btn"></i></a>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="shadows">
                                <div className="price-table-heading">
                                    <ul>
                                        <li>Category</li>
                                        <li>Features</li>
                                        <li>Price</li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">Basic</li>
                                        <li className="content">
                                            <p>SSL Certified,
                                                Upto 8 Pages,
                                                2 Business mail,
                                                Free domain,
                                                Shared hosting,
                                                Call button,
                                                What's app button,
                                                Social media icon,
                                                10 Social media post,
                                                24*7 support,
                                                1 year website maintenance.
                                            </p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>11,999/-</span><sup>*</sup> <span className="price-off">₹<span>14,999/-</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">Standard</li>
                                        <li className="content">
                                            <p>SSL Certified,
                                                Upto 8 Pages,
                                                4 Business mail,
                                                Free domain,
                                                Shared hosting,
                                                Dashboard,
                                                Logo,
                                                Call button,
                                                What's app button,
                                                Map integration,
                                                Payment integration,
                                                Social media icon,
                                                15 Social Media Post,
                                                Data backup,
                                                24*7 support,
                                                1 year website maintenance.. </p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>24,999</span><sup>*</sup> <span className="price-off">₹<span>29,999</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">Premium</li>
                                        <li className="content">
                                            <p>SSL Certified,
                                                Unlimited pages,
                                                4-5 Business mail,
                                                Free domain,
                                                Own hosting,
                                                Dashboard,
                                                Logo,
                                                Call button,
                                                What's app button,
                                                Map integration,
                                                Payment integration,
                                                Social media icon,
                                                30 Social media post,
                                                3 month SEO (up to 7 keyword ),
                                                Data backup,
                                                Company profile,
                                                24*7 support,
                                                1 year website maintenance.</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>49,999</span><sup>*</sup> <span className="price-off">₹<span>54,999</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">E commerce</li>
                                        <li className="content">
                                            <p>SSL Certified,
                                                E-Commerce Website(upto 100 products),
                                                4-5 Business mail,
                                                Free domain,
                                                Own hosting,
                                                Dashboard,
                                                Logo,
                                                Call button,
                                                What's app button,
                                                Map integration,
                                                Payment integration,
                                                Social media icon,
                                                30 Social media post,
                                                6 month SEO (up to 10 keyword ),
                                                Data backup,
                                                Company profile,
                                                24*7 support,
                                                1 year website maintenance,
                                            </p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>79,999</span><sup>*</sup> <span className="price-off">₹<span>84,999</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">Advance</li>
                                        <li className="content">
                                            <p>SSL Certified,
                                                Unlimited pages,
                                                4-5 Business mail,
                                                Free domain,
                                                Own hosting,
                                                Dashboard,
                                                Logo,
                                                Call button,
                                                What's app button,
                                                Map integration,
                                                Payment integration,
                                                Social media icon,
                                                30 Social media post,
                                                1 year SEO (up to 10 keyword ),
                                                Data backup,
                                                Company profile,
                                                24*7 support,
                                                1 year website maintenance.
                                            </p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>99,999</span><sup>*</sup> <span className="price-off">₹<span>104,999</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="header--btn mt15">
                                    <div className="divright">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- <div className="row justify-content-center mt60">
<div className="col-lg-12">
<div className="header--btn  mb30">
<h4>App Development Packages</h4>
<a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mr20">Request A Quote <i className="fas fa-arrow-right fa-btn"></i></a>
</div>
</div>
<div className="col-sm-12">
<div className="shadows">
<div className="price-table-heading">
<ul>
<li>Category</li>
<li>Features</li>
<li>Price</li>
</ul>
</div>
<div className="price-table-ree">
<ul className="price-ree">
<li className="heading ">Basic</li>
<li className="content">
<p>1 Domain Name, 10 Web Pages including 1 Home page, SEO Semantic Html 5 Code, HTML 5 Sitemap Page, Fully Mobile Compatible </p>
<p><strong>2 Design Concepts, 2 Revisions</strong></p>
</li>
<li className="price"> <span className="price-title">starting from</span>$<span>400</span><sup>*</sup> <span className="price-off">$<span>600</span><sup>*</sup></span></li>
</ul>
</div>
<div className="price-table-ree">
<ul className="price-ree">
<li className="heading ">Enhance</li>
<li className="content">
<p>1 Domain Name, 15 Web Pages including 1 Home page, SEO Semantic Html 5 Code, HTML 5 Sitemap Page, Fully Mobile Compatible </p>
<p><strong>3 Design Concepts, 5 Revisions</strong></p>
<p><strong>FREE: Search Engine Submission</strong></p>
</li>
<li className="price"> <span className="price-title">starting from</span>$<span>800</span><sup>*</sup> <span className="price-off">$<span>1000</span><sup>*</sup></span></li>
</ul>
</div>
<div className="price-table-ree">
<ul className="price-ree">
<li className="heading  br-bl">Premium</li>
<li className="content">
<p>1 Domain Name, 50 Web Pages including 1 Home page, SEO Semantic Html 5 Code, HTML 5 Sitemap Page, Fully Mobile Compatible</p>
<p><strong>3 Design Concepts, Unlimited Revisions</strong></p>
<p><strong>FREE: Hosting for one year, Search Engine Submission</strong></p>
</li>
<li className="price br-br"> <span className="price-title">starting from</span>$<span>1200</span><sup>*</sup> <span className="price-off">$<span>1400</span><sup>*</sup></span></li>
</ul>
</div>
</div>
<div className="col-lg-12">
<div className="header--btn mt15">
<div className="divright">
<p>Our web design rates are very reasonable. Know more about our <a href="services.php" className="text-link">App Development Services</a></p>
</div>
</div>
</div>
</div>
</div> --> */}
                    <div className="row justify-content-center mt60">
                        <div className="col-lg-12">
                            <div className="header--btn  mb30">
                                <h4>Digital Marketing Packages</h4>
                                <a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mr20">Request A Quote <i className="fas fa-arrow-right fa-btn"></i></a>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="shadows">
                                <div className="price-table-heading">
                                    <ul>
                                        <li>Category</li>
                                        <li>Features</li>
                                        <li>Price</li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">GOOGLE ADWORD
                                        </li>
                                        <li className="content">
                                            <p> </p>
                                            <p><strong>(MAINTENANCE COST) - 10%-----------------------------------------------------------------------------------------</strong></p>
                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>7000</span><sup>*</sup> <span className="price-off">₹<span>9000</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">FACEBOOK AD<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>4000</span><sup>*</sup> <span className="price-off">₹<span>6000</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">INSTAGRAM AD<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>4000</span><sup>*</sup> <span className="price-off">₹<span>6000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">GOOGLE LISTING<br /></li>
                                        <li className="content">


                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>3000
                                        </span><sup>*</sup> <span className="price-off">₹<span>5000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">SOCIAL MEDIA POST<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>Per Post 200

                                        </span><sup>*</sup> <span className="price-off">₹<span>500</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">SEO (UPTO 10 KEYWORD)<br />
                                        </li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>Per Keyword 1000

                                        </span><sup>*</sup> <span className="price-off">₹<span>3000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">GOOGLE REVIEWS<br />

                                        </li>
                                        <li className="content">


                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>Per Review 30

                                        </span><sup>*</sup> <span className="price-off">₹<span>100</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">PROMOTIONAL SMS<br />

                                        </li>
                                        <li className="content">


                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>10000(1 lac sms)

                                        </span><sup>*</sup> <span className="price-off">₹<span>12000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">WHATSAPP MARKETING<br />

                                        </li>
                                        <li className="content">


                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>12000(1 lac sms)

                                        </span><sup>*</sup> <span className="price-off">₹<span>15000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="header--btn mt15">
                                    <div className="divright">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt60">
                        <div className="col-lg-12">
                            <div className="header--btn  mb30">
                                <h4>Graphic Designing Packages</h4>
                                <a href="get-quote.php" className="ree-btn  ree-btn-grdt2 mr20">Request A Quote <i className="fas fa-arrow-right fa-btn"></i></a>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="shadows">
                                <div className="price-table-heading">
                                    <ul>
                                        <li>Category</li>
                                        <li>Features</li>
                                        <li>Price</li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">LOGO
                                        </li>
                                        <li className="content">
                                            <p> </p>
                                            <p><strong>-------------------------------------------------------------------------------------------------------------------------------</strong></p>
                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>

                                            2000</span><sup>*</sup> <span className="price-off">₹<span>5000</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading ">FLYER<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price"> <span className="price-title">starting from</span>₹<span>400</span><sup>*</sup> <span className="price-off">₹<span>1000</span><sup>*</sup></span></li>
                                    </ul>
                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">BROCHURE<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>3000-8000</span><sup>*</sup> <span className="price-off">₹<span>10000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">LETTERHEAD<br /></li>
                                        <li className="content">


                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>500
                                        </span><sup>*</sup> <span className="price-off">₹<span>1000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">PACKAGING<br /></li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>4000

                                        </span><sup>*</sup> <span className="price-off">₹<span>7000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>
                                <div className="price-table-ree">
                                    <ul className="price-ree">
                                        <li className="heading  br-bl">BUSINESS CARD<br />
                                        </li>
                                        <li className="content">

                                            <p>-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>

                                        </li>
                                        <li className="price br-br"> <span className="price-title">starting from</span>₹<span>500

                                        </span><sup>*</sup> <span className="price-off">₹<span>1000</span><sup>*</sup></span></li>
                                    </ul>

                                </div>



                            </div>
                            <div className="col-lg-12">
                                <div className="header--btn mt15">
                                    <div className="divright">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="sec-pad r-bg-x">
                <div className="container">
                    <div className="ree">
                        <div className="row">
                            <div className="col-lg-6 vcenter">
                                <div className="cta-heading">
                                    <span className="sub-heading mb15">Contact Us</span>
                                    <h2>Have a <span className="ree-text rt40">project</span> in mind? Let's get to chat.</h2>
                                    <a href="get-quote.php" className="ree-btn  ree-btn-grdt1  mt40 mr20"><i className="fas fa-phone-alt fb-bigon"></i> Contact </a>
                                    <a href="https://wa.me/919718600049?text=" className="ree-btn  whatsapp-bg mt40" target="_blank"><i className="fab fa-whatsapp fb-bigon"></i> Whatsapp </a>
                                </div>
                            </div>
                            <div className="col-lg-6 vcenter text-center">
                                <div className="sol-img-png">
                                    <img src="images/banner/chat.png" alt="working with client" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Pricing
