
/* global $ */

import React, { useEffect, useState } from 'react';
import '../css/client.css';
import axios from 'axios';

const ClientSlider = () => {
  const [clients, setClient] = useState([]);

  useEffect(() => {
    axios
      .get('https://aashvienterprises.in/admin/clientapi.php?action=get_json')
      .then(response => {
        // console.log('API Response:', response.data);
        setClient(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setClient([]);
      });
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 800,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }
  }, [clients]);

  if (!Array.isArray(clients) || clients.length === 0) {
    return (
      <div className="col-lg-12 text-center mt-5 pt-5">
        <p>No products to show.</p>
      </div>
    );
  }

  return (
    <>
      <section className="sec-pad">
        <div className="container">
          <h2 className="client-title">Our Partners</h2>
          <div className="customer-logos slider">
            {clients.map(client => (
              <div className="slide" key={client.id}>
                <img src={client.image} alt={client.name} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientSlider;
