import React from 'react'

import { Link } from 'react-router-dom'
import logo from '../logo.png';
import InstallButton from './InstallButton';

const Footer = () => {
  return (
    <>
      <footer className="footer-a">
        <div className="footer-fist-row pt40">
            <div className="container">
                <div className="footer-rowset">
                    <div className="col-md-3 footer-head">
                        <div className="footer-logo">
                            <h5> </h5>
                        <img src={logo} className="w-100" alt='logo'/>
                    </div>
                    <InstallButton/>
                    </div>
                    <div className="col-md-3 footer-head">
                        <h5>Contact info</h5>
                        <ul className="footer-links-list social-linkz">
                            <li><Link to="tel:+919718600049"><span><i className="fas fa-phone-square"></i></span>+919718600049</Link></li>
                            <li><Link to="tel:+919212300049"><span><i className="fas fa-phone-square"></i></span> +919212300049
                                    </Link></li>
                            <li><Link
                                    to="mailto:info@aashvienterprises.in"><span><i
                                            className="fas fa-envelope"></i></span> <span>info@aashvienterprises.in</span></Link>
                            </li>
                        
                            <li><Link to="" target="blank" rel="noreferrer"><span><i className="fas fa-location-arrow"></i></span>
                                    OFFICE-AE-36,2ND FLOOR, AVANTIKA,GHAZIABAD(201002)</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 footer-head">
                        <h5>Follow Us</h5>
                        <ul className="footer-links-list social-linkz">
                            <li><Link to="https://www.facebook.com/aashvienterprisesdm?mibextid=ZbWKwL" target="_blank" rel="noreferrer"><span><i className="fab fa-facebook-f"></i></span> Facebook</Link>
                            </li>
                            <li><Link to="https://twitter.com/AashviDigital" target="_blank" rel="noreferrer"><span><i className="fab fa-twitter"></i></span> Twitter </Link>
                            </li>
                            <li><Link to="https://www.instagram.com/aashvi_enterprises/" target="_blank" rel="noreferrer"><span><i className="fab fa-instagram"></i></span> Instagram</Link>
                            </li>
                          {/* <!--   <li><Link to="javascript:void(0)"><span><i className="fab fa-pinterest-p"></i></span>
                                    Pinterest</Link></li> -->
                            <li><Link to="https://www.linkedin.com/in/aashvi-enterprises" target="_blank"><span><i className="fab fa-linkedin-in"></i></span>
                                    linkedin</Link></li>
                          <!--   <li><Link to="javascript:void(0)"><span><i className="fab fa-youtube"></i></span> Youtube</Link>
                            </li> --> */}
                        </ul>
                    </div>
                    <div className="col-md-3 footer-head">
                        <h5>Sitemap</h5>
                        <ul className="footer-links-list">
                            <li><Link to="/">Home</Link></li>
                          
                            <li><Link to="/price">Pricing</Link></li>
                            <li><Link to="/carrer">Career</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="https://aashvienterprises.in/admin" target="_blank" rel="noreferrer">Admin</Link></li>
                        </ul>
                    </div>
                
               
                </div>
            </div>
         
            <div className="container ft-cpy">
                <div className="row">
                 
                    <div className="col-lg-12">
                        <div className="ft-copyright ft-r">
                            <p>Copyright © 2020 Aashvi Enterprises. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
