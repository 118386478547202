/* global $ */

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/plugin.min.css";
import "./css/ionicon.min.css";
import "./css/style.css";
import "./css/responsive.css";
import './css/slick.min.css';

import "./App.css";
import NavBar from "./components/NavBar";
import "../node_modules/fontawesome-free-6.2.1/css/all.min.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./components/Footer";
import Service from "./pages/Service";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Carrer from "./pages/Carrer";
import Pricing from "./pages/Pricing";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./pages/Services";
import PopUp from "./components/PopUp";
import Liasioning from "./pages/Liasioning";







function App() {

   

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
    });
  }, []);
  useEffect(() => {
    // Make an API request to update the visitor count
    fetch('https://aashvienterprises.in/admin/visitcount.php', {
      method: 'POST', // You can use GET, POST, or any other method you prefer
      headers: {
        'Content-Type': 'application/json', // Set the content type
      },
      // You can send additional data in the body if needed
      body: JSON.stringify({}),
     
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.message); // Should print 'Visitor count updated successfully'
      })
      .catch(error => {
        console.error('Error updating visitor count:', error);
      });
  }, []);



  return (
    <div className="App">

      <BrowserRouter>
 <PopUp/>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" Component={Home}></Route>
        <Route exact path="/about" Component={About}></Route>
        <Route exact path="/service" Component={Service}></Route>
        <Route exact path="/portfolio" Component={Portfolio}></Route>
        <Route exact path="/contact" Component={Contact}></Route>
        <Route exact path="/career" Component={Carrer}></Route>
        <Route exact path="/pricing" Component={Pricing}></Route>
        <Route exact path="/services/:slug" Component={Services}></Route>
        <Route exact path="/liasioning" Component={Liasioning}></Route>
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
