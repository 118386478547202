import React from 'react';

import app from '../app.png';

const InstallButton = () => {
  const handleInstall = () => {
    const deferredPrompt = window.deferredPrompt;

    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });

      window.deferredPrompt = null;
    }
  };

  return (
    <div className='btn-wrap text-center'>
    <button onClick={handleInstall} className='install-btn w-50 mx-auto' >
      <img src={app} className='img-fluid' alt='app'></img>
    </button>
    </div>
  );
};

export default InstallButton;
