import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import logo from '../logo.png';


const NavBar = () => {

    const [users, setUsers] = useState([]);


    useEffect(() => {
        axios.get('https://aashvienterprises.in/admin/serviceapi.php?action=get_json')
            .then(response => {
                //  console.log('API Response:', response.data);
                setUsers(response.data);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setUsers([]); // Set users to an empty array to handle the error response

            });
    }, []);





    /*--menu-- */
    const [isOpen, setIsOpen] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);

    };
    const handleToggleSubmenu = (submenu) => {
        setActiveSubmenu(activeSubmenu === submenu ? null : submenu);



        const navContainer = document.querySelector('.nav-container');
        if (navContainer) {
            if (submenu) {
                navContainer.classList.add('active-submenu');
            } else {
                navContainer.classList.remove('active-submenu');
            }
        }
    };

    const handleLinkClick = () => {
        setIsOpen(false);
        document.body.classList.remove('nav-open');
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('hc-nav-open');
        } else {
            document.body.classList.remove('hc-nav-open');
        }
    }, [isOpen]);


    if (!Array.isArray(users) || users.length === 0) {
        return (

            <span></span>


        );
    }

    return (
        <>
            <div id="hc-nav-1"
                className={`hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-left disable-body nav-close-button-empty ${isOpen ? 'nav-open' : ''}`}
            >
                <div className="nav-container" >
                    <div className="nav-wrapper nav-wrapper-0" >
                        <div className="nav-content">
                            <h2 id="hc-nav-1-nav-title" className="nav-title">Menu</h2>
                            <div className="nav-close"><a href="#" className="nav-close-button" onClick={handleToggleMenu} ><span></span></a></div>
                            <ul role="menu" >
                                <li className="nav-item">
                                    <div className="nav-item-wrapper"><Link to="/" className="nav-item-link"
                                        role="menuitem" onClick={handleLinkClick}>Home</Link></div>
                                </li>
                                <li className={`nav-item nav-parent ${activeSubmenu === 'submenu1' ? 'active' : ''}`}>
                                    <div className="nav-item-wrapper">
                                        <a
                                            href="#"
                                            className="nav-item-link"
                                            onClick={() => handleToggleSubmenu('submenu1')}
                                        >
                                            about
                                            <span className="nav-next">
                                                <span></span>
                                            </span>
                                        </a>
                                    </div>
                                    {activeSubmenu === 'submenu1' && (
                                        <div className="nav-wrapper nav-wrapper-1 active">
                                            <div className="nav-content">
                                                <h2 className="level-title">about</h2>
                                                <div className="nav-back">
                                                    <div className="nav-item-wrapper">
                                                        <a
                                                            href="#"
                                                            className="nav-back-button"
                                                            role="menuitem"
                                                            onClick={() => handleToggleSubmenu(null)}
                                                        >
                                                            Menu<span></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <ul id="submenu">
                                                    <li className="nav-item">
                                                        <div className="nav-item-wrapper">
                                                            <Link to="/about" className="nav-item-link" onClick={handleLinkClick}>
                                                                About Us
                                                            </Link>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item">
                                                        <div className="nav-item-wrapper">
                                                            <Link to="/career" className="nav-item-link" onClick={handleLinkClick}>
                                                                Career
                                                            </Link>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item">
                                                        <div className="nav-item-wrapper">
                                                            <Link to="/pricing" className="nav-item-link" onClick={handleLinkClick}>
                                                                Pricing
                                                            </Link>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <div className="nav-item-wrapper"><Link to="/portfolio" className="nav-item-link" onClick={handleLinkClick}>Portfolio</Link></div>
                                </li>
                                <li className={`nav-item nav-parent ${activeSubmenu === 'submenu2' ? 'active' : ''}`}>
                                    <div className="nav-item-wrapper">
                                        <a
                                            href="#"
                                            className="nav-item-link"
                                            onClick={() => handleToggleSubmenu('submenu2')}
                                        >
                                            Service
                                            <span className="nav-next">
                                                <span></span>
                                            </span>
                                        </a>
                                    </div>
                                    {activeSubmenu === 'submenu2' && (
                                        <div className="nav-wrapper nav-wrapper-1 active">
                                            <div className="nav-content">
                                                <h2 className="level-title">Service</h2>
                                                <div className="nav-back">
                                                    <div className="nav-item-wrapper">
                                                        <a
                                                            href="#"
                                                            className="nav-back-button"
                                                            role="menuitem"
                                                            onClick={() => handleToggleSubmenu(null)}
                                                        >
                                                            Menu<span></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <ul id="submenu">

                                                    {users.map(user => (
                                                        <li key={user.id} className="nav-item">
                                                            <div className="nav-item-wrapper">
                                                                <Link className="nav-item-link" to={`/services/${user.slug}`} onClick={handleLinkClick}>{user.sname}</Link>
                                                            </div>
                                                        </li>
                                                    ))}

                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <div className="nav-item-wrapper"><Link to="https://aashvienterprises.in/real-estate/" className="nav-item-link" onClick={handleLinkClick} target="_blank" rel="noreferrer">Real Estate</Link></div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-item-wrapper"><Link to="/liasioning" className="nav-item-link" onClick={handleLinkClick}>Liasioning</Link></div>
                                </li>

                                <li className="nav-item">
                                    <div className="nav-item-wrapper"><Link to="/contact" className="nav-item-link" onClick={handleLinkClick}>Contact Us</Link></div>
                                </li>
                            </ul>
                            <ul role="menu" aria-level="1" className="bottom-nav">
                                <li className="nav-item ree-hc">
                                    <div className="nav-item-wrapper"><Link to="tel:9718600049" rel="noreferrer" target="_blank"
                                        className="nav-item-link" >
                                        <i className="fa-solid fa-phone"></i>
                                    </Link></div>
                                </li>
                                <li className="nav-item ree-hc">
                                    <div className="nav-item-wrapper"><Link to="mailto:info@aashvienterprises.in" target="_blank" rel="noreferrer"
                                        className="nav-item-link" >
                                        <i className="fa-solid fa-envelope"></i>
                                    </Link></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <header className="ree-header fixed-top">
                <div className="container m-p-l-r-0">
                    <div className="menu-header horzontl">
                        <div className="menu-logo">
                            <div className="dskt-logo"><Link className="nav-brand" to="/"> <img src={logo} alt="Logo"
                                className="ree-logo" /> </Link> </div>
                        </div>
                        <div className="ree-nav" role="navigation">
                            <ul className="nav-list">
                                <li className="megamenu">
                                    <Link to="/" className="menu-links">Home</Link>
                                </li>
                                <li className="megamenu mega-small">
                                    <Link to="#" className="menu-links">about</Link>
                                    <div className="menu-dropdown">
                                        <div className="menu-block-set">
                                            <Link className="dropdown-item" to="/about">About Us</Link>
                                            <Link className="dropdown-item" to="/career">Career</Link>
                                            <Link className="dropdown-item" to="/pricing">Pricing</Link>
                                            {/* <!-- <Link className="dropdown-item" to="our-team.php">Our Team</Link> -->
                                    <!-- <Link className="dropdown-item" to="why-us.php">Why Us</Link> --> */}
                                        </div>
                                    </div>
                                </li>

                                <li className="megamenu mega-small">
                                    <Link to="/service" className="menu-links">Services</Link>
                                    <div className="menu-dropdown">
                                        <ul className="menu-block-set">
                                            {users.map(user => (
                                                <li key={user.id}>
                                                    <Link className="dropdown-item" to={`/services/${user.slug}`}>{user.sname}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>

                                <li className="megamenu mega-small ">
                                    <Link to="/portfolio" className="menu-links">Portfolio</Link>

                                </li>
                                {/* <!-- <li className="megamenu mega-small">
                            <Link to="blog.php" className="menu-links">Blogs</Link>
                           
                        </li> --> */}

                                <li className="megamenu mega-small">
                                    <Link to="https://aashvienterprises.in/real-estate/" className="menu-links" target="_blank" rel="noreferrer">Real Estate</Link>

                                </li>
                                <li className="megamenu mega-small">
                                    <Link to="/liasioning" className="menu-links">Liasioning</Link>

                                </li>

                                <li className="megamenu mega-small">
                                    <Link to="/contact" className="menu-links">Reach Us</Link>

                                </li>
                            </ul>
                        </div>
                        <div className="ree-nav-cta">
                            <ul>
                                <li><Link to="tel:+919718600049" className="ree-btn ree-btn0 ree-btn-grdt2" ><i className="fas fa-phone"></i> Contact</Link> </li>
                            </ul>
                        </div>
                        <div className="mobile-menu2">
                            <ul className="mob-nav2">
                                {/* <!--    <li><Link to="get-quote.php" className="ree-btn2 ree-btn-grdt1"><i
                                    className="fas fa-envelope-open-text"></i></Link></li> --> */}
                                <li className="navm-"><a className="toggle hc-nav-trigger hc-nav-1" href='#' onClick={handleToggleMenu}> <span className=''></span> </a> </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </header>

        </>
    )
}

export default NavBar
