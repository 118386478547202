import React, { useState } from 'react'

const Contact = () => {
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        interestedIn: '',
        message: '',
      }; 
    
      const [formData, setFormData] = useState(initialFormData);
      const [successMessage, setSuccessMessage] = useState('');
    
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        fetch('https://aashvienterprises.in/admin/enquiry.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.statusText);
            }
            return response.json();
          })
          .then((data) => {
            console.log('Success:', data);
            setSuccessMessage('Thank You for contacting us');
            setFormData(initialFormData);
            // Clear the success message after 4 seconds
        setTimeout(() => {
            setSuccessMessage('');
          }, 4000); // Reset form fields to initial values
          })
          .catch((error) => {
            console.error('Error:', error.message);
          });
      };
    
    return (
        <>
       
            <div className="contact-form-sec sec-pad r-bg-d">
                <div className="container">
                    <div className="row flx-end">
                        <div className="col-lg-6">

                            <img src="images/banner/chat.png" className="w-100" alt='banner' />


                        </div>
                        <div className="col-lg-6">
                            <div className="form-contact-hom m-mt60">
                                <div className="form-block bg-w">
                                    <div className="form-head"><h4>REACH US</h4>
                                    {successMessage && <p className="success-message text-success mt-2">{successMessage}</p>}
                                    </div>
                                    <div className="form-body">
                                  
                                        <form onSubmit={handleSubmit}>
                                            <div className="fieldsets row">
                                                <div className="col-md-6"><input type="text" placeholder="Full Name" name="name" value={formData.name} onChange={handleInputChange} /></div>
                                                <div className="col-md-6"><input type="email" placeholder="Email Address" name="email" value={formData.email}
                                                    onChange={handleInputChange} /></div>
                                            </div>
                                            <div className="fieldsets row">
                                                <div className="col-md-6"><input type="number" placeholder="Contact Number" name="phone" value={formData.phone} onChange={handleInputChange} /></div>
                                                <div className="col-md-6">
                                                    <select name="interestedIn" value={formData.interestedIn} onChange={handleInputChange}>
                                                        <option value="">interested in</option>
                                                        <option value="Web Design & Development">Web Design & Development</option>
                                                        <option value="Graphic Design">Graphic Design</option>
                                                        <option value="App Development">App Development</option>
                                                        <option value="Digital Marketing">Digital Marketing</option>
                                                  
                                                        <option value="Other...">Other...</option>
                                                    </select>
                                                </div>
                                            </div>




                                            <div className="fieldsets"><textarea placeholder="Message" name="message" value={formData.message}
                                                onChange={handleInputChange}></textarea></div>

                                            <div className="fieldsets mt20"> <button type="submit" name="submit" className="ree-btn  ree-btn-grdt1 w-100">Send your inquiry <i className="fas fa-arrow-right fa-btn"></i></button> </div>
                                            <p className="trm"><i className="fas fa-lock"></i>We hate spam, and we respect your privacy.</p>
                                            <div className="form-btm-set text-center mt15">
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Contact
