import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Services = () => {

    const { slug } = useParams();
    const [service, setService] = useState([]);
  
    useEffect(() => {
      axios
        .get(`https://aashvienterprises.in/admin/serviceapi.php?action=read_single_by_slug&slug=${slug}`)
        .then(response => {
          // console.log('Service Data:', response.data);
          setService(response.data);
        })
        .catch(error => {
          console.error('Error fetching service data:', error);
          setService(null);
        });
    }, [slug]);
    const imageSrc = `${service.mimage}`;
    const imageSrc2 = `${service.bimage}`;

  return (
    <>
     <div>
    

<section className="page-heading-sec bread serv-page-head r-bg-a  pb120" style={{ backgroundImage: `url(${imageSrc2})` }}>
<div className="container">
<div className="row pt80 justify-content-center">
<div className="col-lg-9">
<div className="page-headings service-page">
{/* <!-- <span className="sub-heading mb15" data-aos="fade-in" data-aos-delay="200">Our Services</span> --> */}
{/* <h1 data-aos="fade-in" data-aos-delay="400">Our Services</h1> */}

</div>
</div>
</div>

</div>
</section>


<section className="">
    <div className="container">
        <div className="row justify-content-around p-5" >
            <div className="col-lg-5 shadow-lg p-3">
                <img className="img-fluid"  src={imageSrc} alt="web"/>
            </div>
            <div className="col-lg-5 mt-5 mb-5">{service.des}</div>
        </div>
    </div>
</section>




</div>
 
    </>
  )
}

export default Services
