import axios from 'axios';
import React, { useEffect, useState } from 'react'



const Liasioning = () => {
  const [lic, setLic] = useState([]);


  useEffect(() => {
    axios.get('https://aashvienterprises.in/admin/licapi.php?action=get_json').then(response => {
      setLic(response.data);
    }).catch(error=>{
      console.error('Error fetching data:', error);
    });
  }, []);

  return (
    <>

      <section className="header-title head-opacity" data-background="images/liq.png" style={{ backgroundImage: `url(/images/liq.png)` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center ovrly">
              <h1 className="mb15">Liasioning</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="sec-pad r-bg-a">
        <div className="container liq">
          <div className="row">

            {lic.map(lice => (
              <div className="col-md-4 mb-4" key={lice.id}>
                <div className="shadow">
                  <h4 className='p-5'>{lice.title}</h4>
                  <p>{lice.desc}</p>
                </div>
              </div>
            ))}


          </div>
        </div>
      </div>

    </>
  )
}

export default Liasioning
