import axios from 'axios';
import React, { useEffect, useState } from 'react';

const PopUp = () => {
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    axios.get('https://aashvienterprises.in/admin/popdata.php')
      .then(response => {
        setPopupData(response.data);
      })
      .catch(error => {
        console.error('Error fetching pop-up data:', error);
      });
  }, []);

  const handleClosePopup = () => {
    // Update the display status in the database
    axios.post('https://aashvienterprises.in/admin/popstatus.php', { id: popupData.id })
      .then(response => {
        setPopupData(null); // Resetting the state after updating
      })
      .catch(error => {
        console.error('Error updating pop-up status:', error);
      });
  };

  if (popupData && popupData.displayed === '0') {
    return (
      <div className='popwrap position-fixed overflow-scroll'>
        <div className='container justify-content-center my-auto overflow-scroll'>
          <div className="col-md-5 col-lg-5 p-2 bg-white popup mx-auto " key={popupData.id}>
            <button className='position-absolute close-btn' onClick={handleClosePopup}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <img
              src={popupData.image}
              className='img-fluid '
              alt={popupData.alt}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null; // No pop-up to display
  }
};

export default PopUp;
