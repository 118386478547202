import React from 'react'

const About = () => {
  return (
    <>
<section className="header-title head-opacity" data-background={process.env.PUBLIC_URL + "/images/banner/3.png"} style={{ backgroundImage: `url(/images/banner/3.png)` }}>
<div className="container">
<div className="row justify-content-center">
<div className="col-lg-7 text-center ovrly">
<h2 className="mb15">About Us</h2>
<p className="h-light">Aashvi Enterprises is a Marketing and Advertising Agency
catering services all over the states in India. Ashvi enterprises
is into Services like Bulk SMS, WhatsApp Marketing,
Digital Marketing Voice Calls and many more. Aashvi enterprises
is presently serving its services throughout India. Wit
our values attached to our core, we firmly believe in
providing our customers with high quality work.
</p>
</div>
</div>
</div>
</section>


<section className="page-heading-sec about-pag-head sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-6">
<div className="page-heading mr25">
<span className="sub-heading mb15" data-aos="fade-up" data-aos-delay="200">Who We Are</span>
<h2 data-aos="fade-up" data-aos-delay="400"> We are a  digital marketing and web development agency based in India.</h2>
<p className="mt30" data-aos="fade-up" data-aos-delay="600">Incepted in the year 2019, “Aashvi Enterprises” is a distinguished Service Provider offering an enormous consignment of Hosting Services, Adwords Service, Marketing Services etc.</p>
<p className="mt15" data-aos="fade-up" data-aos-delay="800">With us, you can rest assured to get the accountable and reliable services which will not outsource your important work. We are a team of highly trained professionals, engineers, designers and developers etc who make it sure that services delivered are same as promised.</p>
</div>
</div>
<div className="col-lg-6">
<div className="sol-img m-mt30">
<img src="images/banner/office_cubicle.jpg" alt="reevan office" className="img-fluid" data-aos="fade-in" data-aos-delay="400"/>
</div>
</div>
</div>
</div>
</section>


<section className="r-bg-c sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-4">
<div className="page-headings side-pghd">
<span className="sub-heading mb15">About US</span>
<h2>Aashvi Enterprises</h2>
</div>
</div>
<div className="col-lg-8">
<h4 className="mb15">Web Development & Digital Marketing Company</h4>
<p>Incepted in the year 2019, “Aashvi Enterprises” is a distinguished Service Provider offering an enormous consignment of Hosting Services, Adwords Service, Marketing Services etc.</p>
<p className="mt15">With us, you can rest assured to get the accountable and reliable services which will not outsource your important work. We are a team of highly trained professionals, engineers, designers and developers etc who make it sure that services delivered are same as promised.</p>
<div className="row justify-content-center">
<div className="col-lg-12">
<div className="company-stats2 roww mt30">
<div className="statsnum counter-number mt30"> <p>Complete Projects</p> <span className="counter">200</span> <span>+</span> </div>
<div className="statsnum counter-number mt30"> <p> Clients</p> <span className="counter">150</span> <span>+</span> </div>
<div className="statsnum counter-number mt30"> <p>Expert Team</p> <span className="counter">20</span><span>+</span> </div>
<div className="statsnum counter-number mt30"> <p>Success Years</p> <span className="counter">2</span><span>+</span> </div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>





<section className="r-bg-a  sec-pad">
<div className="container">
<div className="row">
<div className="col-lg-4 col-sm-12">

<ul className="vrt-tabb nav nav-pills nav-pills-custom" id="pills-tab" role="tablist" aria-orientation="vertical">
<li>
<a className="nav-link active" id="mission-tab" data-toggle="pill" href="#pills-mission" role="tab" aria-controls="pills-mission" aria-selected="true">
<i className="fas fa-rocket"></i> Our Mission</a>
</li>
<li> <a className="nav-link" id="vision-tab" data-toggle="pill" href="#pills-vision" role="tab" aria-controls="pills-vision" aria-selected="false">
<i className="far fa-eye"></i> Our Vision</a>
</li>


</ul>
</div>
<div className="col-lg-8 col-sm-12">

<div className="tab-content tab-bg" id="pills-tabContent">
<div className="tab-pane fade  show active" id="pills-mission" role="tabpanel" aria-labelledby="mission-tab"> <h3 className="mb15">Our Mission</h3>
<p>Our customers and their satisfaction is our priority. Our mission is to work with our clients/customers and provide them with the best possible services that will enable our clients business to prosper along with our company’s development. We understand the ever changing and demanding nature of business environment and help them all the possible IT Solutions that are in alignment with their business goals.</p>

</div>
<div className="tab-pane fade" id="pills-vision" role="tabpanel" aria-labelledby="vision-tab"> <h3 className="mb15">Our Vision</h3>
<p>Our vision is to be the best Advertising & Marketing
Company that is associated with top quality work and
unparalleled customer satisfaction. Our mission is to
align our resources and work towards our mission and
goal, keeping in mind the integrity of our organization.</p>

</div>


</div>
</div>
</div>
</div>
</section>


<section className="r-bg-x  sec-pad">
<div className="container">
<div className="row justify-content-center">
<div className="col-lg-7 text-center">
<div className="page-headings">
<h2>We are Impeccable</h2>
<p className="mt15">Our willingness to take chances and turn them opportunities helps us stand out! With an awesome team, we deliver faster results with excellent support</p>
</div>
</div>
</div>
<div className="row mt30">
<div className="col-lg-4 col-sm-6">
<div className="ree-card r-bg-c mt60">
<div className="ree-card-img"><img src="images/icons/customer-service.png" alt="services"/> </div>
<div className="ree-card-content mt40">
<h3 className="mb15">Excellent Support</h3>
<p>Great leaders are built by being engaged with the team and working with them. They understand that respect is earned, through empathy, compassion, and trust.</p>
</div>
</div>
</div>
<div className="col-lg-4 col-sm-6">
<div className="ree-card r-bg-c mt60">
<div className="ree-card-img"><img src="images/icons/networking.png" alt="services"/> </div>
<div className="ree-card-content mt40">
<h3 className="mb15">Awesome Team</h3>
<p>Since teamwork embodies many constraints and rewards at the same time, it is impossible to do a task with a definite number of benefits for businesses without an awesome team.</p>
</div>
</div>
</div>
<div className="col-lg-4 col-sm-6">
<div className="ree-card r-bg-c mt60">
<div className="ree-card-img"><img src="images/icons/innovation.png" alt="services"/> </div>
<div className="ree-card-content mt40">
<h3 className="mb15">Faster Results</h3>
<p>With the pace at which society progresses, companies have to do whatever it takes to stay relevant. And, we run ahead of time when it comes to giving results.</p>
</div>
</div>
</div>



</div>
</div>
</section>


<section className="r-bg-a  sec-pad">
<div className="container">
<div className="ree">
<div className="row">
<div className="col-lg-6 vcenter">
<div className="cta-heading">
<span className="sub-heading mb15">Career</span>
<h2><span className="ree-text rt40">Teamwork</span> is the ability to work together toward a common vision.</h2>
<a href="get-quote.php" className="ree-btn  ree-btn-grdt1 mt40 mw-80">Join Our Team <i className="fas fa-arrow-right fa-btn"></i></a>
</div>
</div>
<div className="col-lg-6 vcenter">
<div className="ct-sol-img">
<img src="images/banner/join.jpg" alt="team" className="img-fluid"/>
</div>
</div>
</div>
</div>
</div>
</section> 
    </>
  )
}

export default About
